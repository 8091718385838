import { useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import type { Price } from 'generated/graphql';
import { PurchaseOptionType } from 'src/components/gift-cards/gift-cards-purchase-hero';
import useGeoInfo from 'src/hooks/use-geo-info';
import { useShowNotification } from 'src/hooks/use-notification';
import useSdk, { ProductsStripeQuery } from 'src/hooks/use-sdk';
import useTicketStatus from 'src/hooks/use-ticket-status';
import useTranslate from 'src/hooks/use-translate';
import { captureException } from 'src/utilities/exceptions';

const giftPurchaseIdKey = 'giftPurchaseId';

/**
 * Store the `purchaseId` in the session storage to be able to query the purchase status later
 * @param purchaseId
 */
export function storePurchaseId(purchaseId: string | null) {
  if (typeof window === 'undefined') {
    return;
  }
  // if purchaseId is undefined, remove the key from the session storage
  if (purchaseId === null) {
    window.sessionStorage.removeItem(giftPurchaseIdKey);
    return;
  }
  window.sessionStorage.setItem(giftPurchaseIdKey, purchaseId);
}

/**
 * Get the `purchaseId` from the session storage
 **/
export function getPurchaseId(): string | null {
  if (typeof window === 'undefined') {
    return null;
  }
  return window.sessionStorage.getItem(giftPurchaseIdKey)
    ? String(window.sessionStorage.getItem(giftPurchaseIdKey))
    : null;
}

/**
 * Get the e-ticket price in the user's currency. This value is also used to
 * display both options on the Gift cards page.
 */
function useETicketPrice(products: ProductsStripeQuery): Price | undefined {
  const { data: geoInfo } = useGeoInfo();
  const eTicketProduct = products.productsStripe.find(({ id }) => id === 'e_ticket');
  return eTicketProduct?.prices?.find((price) => price.currency === geoInfo?.currencyCode);
}

/**
 * Hook that manages status and behavior of the Gifts Cards landing page
 */
export function useGiftCards({ products }: { products: ProductsStripeQuery }) {
  // Every time the user visits this page, we should initiate a new checkout
  // session. The persisted purchaseID is used to query the purchase status on
  // the "thank you" page to which Stripe redirects the user after successful or
  // failing checkout attempt and we use it to query the said status – in order
  // to avoid querying the previous checkout session, we always delete the
  // previous purchase id on the visit to the landing page that initiates new
  // checkout session.
  useEffect(() => {
    // Clean the session storage if we land on the "purchase ticket" page
    storePurchaseId(null);
  }, []);

  const sdk = useSdk();
  const t = useTranslate();
  const router = useRouter();
  const showNotification = useShowNotification();
  const { isValid: userHasSubscription, isMonthly: userHasMonthlySubscription } = useTicketStatus();

  // Only users without a subscription or with a monthly subscription are able
  // to purchase a yearly subscription
  const disableAnnualSubscription = userHasSubscription && !userHasMonthlySubscription;

  const price = useETicketPrice(products);

  const [selectedOption, setSelectedOption] = useState<PurchaseOptionType>(PurchaseOptionType.AnnualSubscription);

  // Preselect the Gift Card option instead of the default Annual Subscription
  // (/gift-cards#gift)
  useEffect(() => {
    const shouldPreselectGift = typeof window !== 'undefined' && window.location.hash === '#gift';
    if (shouldPreselectGift || disableAnnualSubscription) {
      setSelectedOption(PurchaseOptionType.GiftCard);
    }
  }, [disableAnnualSubscription]);

  // When the use chooses the annual subscription, we redirect them to the Tickets
  // page, where they follow the normal subscription flow
  const handleSubscriptionSubmit = useCallback(async () => {
    await router.push(
      userHasSubscription && userHasMonthlySubscription
        ? '/settings/subscription/change'
        : '/account/signup?redirect=%2Fgift-card-subscription',
    );
  }, [router, userHasSubscription, userHasMonthlySubscription]);

  // When user chooses to purchase a gift code, we initiate a new Stripe checkout session using
  // previously preloaded product object, selecting the currency of the checkout based on the
  // user location and providing a priceId as the argument to start Stripe checkout.
  // We provide a callback URL where Stripe redirects the user after successful checkout. The failed
  // payment states are handled by Stripe itself (errors are shown on the Stripe Checkout page).
  // Note, that since the user navigates away from our domain, we must persist the purchaseID
  // in order to query the status of the purchase later (on /thank-you page).
  const handleGiftCardSubmit = useCallback(async () => {
    if (price?.id === undefined) {
      return;
    }

    try {
      const { setupProductPurchase } = await sdk.setupProductPurchase({
        input: {
          priceId: price?.id,
          successUrl: `${location.origin}/thank-you`,
        },
      });

      // Store purchaseId, so it can be used to query the purchase status later on the "thank you" page
      if (setupProductPurchase?.result?.purchaseId) {
        storePurchaseId(setupProductPurchase.result.purchaseId);
      }

      // Redirect to the URL returned by the backend
      // Not using the Next router because it might be outside of the Stage+ app
      if (setupProductPurchase?.result?.url) {
        window.location.href = setupProductPurchase.result.url;
      }
    } catch (error) {
      // Show a generic error notification
      showNotification({
        title: t('error_page_generic__title'),
        text: t('error_page_generic__message'),
        type: 'error',
      });
      // Log the error to Sentry
      captureException(error);
    }
  }, [price?.id, sdk, showNotification, t]);

  const onSubmit = useCallback(async () => {
    await (selectedOption === PurchaseOptionType.AnnualSubscription
      ? handleSubscriptionSubmit()
      : handleGiftCardSubmit());
  }, [selectedOption, handleSubscriptionSubmit, handleGiftCardSubmit]);

  const onChangeOption = (option: PurchaseOptionType) => {
    setSelectedOption(option);
  };

  return { price, selectedOption, disableAnnualSubscription, onSubmit, onChangeOption };
}
