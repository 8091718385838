import type { CSSProperties, ReactNode } from 'react';

type CampaignThemeProps = {
  /** Accent color in HSL format (for example, 38.5 46.9% 61.6%) */
  accent: string;
  children: ReactNode;
};

/**
 * Define custom colors for promo campaign components.
 */
export function CampaignTheme({ accent, children }: CampaignThemeProps) {
  // Override the value of a CSS variable defined by Tailwind theme
  // tw-colors creates variables for each theme colors such as: campaignAccent → --twc-campaignAccent
  // Each such variable contains a color in HSL format (for example, 38.5 46.9% 61.6%)
  // This component will override these colors for all its children.
  // Type casting is necessary because React doesn't recognize CSS variables here,
  // though it's a valid way of setting them.
  return <div style={{ '--twc-campaignAccent': accent } as CSSProperties}>{children}</div>;
}
