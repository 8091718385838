import Translate from 'src/components/translate';
import useCountdown from 'src/hooks/use-countdown';
import useTranslate from 'src/hooks/use-translate';
import { secondsToDuration } from 'src/utilities/date-helpers';

/**
 * A basic countdown component that displays the time remaining until a given deadline.
 */
function CampaignCountdown({ deadline }: { deadline: Date }) {
  const t = useTranslate();
  const secondsUntilStart = useCountdown(deadline);
  const duration = secondsToDuration(secondsUntilStart);
  const units = [
    { value: duration.days, label: t('component__countdown_days_unit') },
    { value: duration.hours, label: t('component__countdown_hours_unit') },
    { value: duration.minutes, label: t('component__countdown_minutes_unit') },
    { value: duration.seconds, label: t('component__countdown_seconds_unit') },
  ];

  if (secondsUntilStart <= 0) {
    // Hide the countdown when it reaches zero
    return null;
  }

  return (
    <div
      className={'select-none tabular-nums text-support-live typo-subheadline'}
      // we have to suppress the hydration warning because we are changing the text content
      data-test="campaign-countdown"
    >
      <Translate
        i18nKey={'component__campaign_countdown_label'}
        values={{
          time: units.map(({ value, label }) => `${value}${label}`).join(' '),
        }}
      />
    </div>
  );
}

export default CampaignCountdown;
