/**
 * This file contains the configuration for a temporary marketing campaign.
 * You can use this file to define the start and end date of the campaign, and
 * to create a hook that checks if the campaign is currently active.
 */
import useCampaignActive from 'src/hooks/use-campaign-active';

// campaign start date is 18th November 2024, 12pm Berlin time
// acknowledge the time zone difference between Berlin and the server time
export const campaignStartTime = new Date('2024-11-18T11:00:00Z');
// end of 6th January 2026, Berlin time
export const campaignEndTime = new Date('2025-01-06T22:59:59Z');
// a dedicated hook to check if the seasonal campaign is active
export const useSeasonCampaignActive = () => useCampaignActive(campaignStartTime, campaignEndTime);
