import { useCallback, useState } from 'react';
import useInterval from 'src/hooks/use-interval';

const defaultRegularity = 1000 * 60; // 1 minute

function isWithinDates(startDate: Date, endDate: Date) {
  return new Date() > startDate && new Date() < endDate;
}

/**
 * A generic hook that checks if a campaign is still running
 * @param startDate the start date of the campaign
 * @param endDate the end date of the campaign
 */

const useCampaignActive = (startDate: Date, endDate: Date, checkRegularity = defaultRegularity) => {
  const [isCampaignOn, setIsCampaignOn] = useState(isWithinDates(startDate, endDate));

  // update the state if the campaign is still running
  const checkCampaign = useCallback(() => {
    setIsCampaignOn(isWithinDates(startDate, endDate));
  }, [startDate, endDate]);

  // check the campaign every minute
  useInterval(checkCampaign, checkRegularity);

  return isCampaignOn;
};

export default useCampaignActive;
