import { useState, type FormEvent } from 'react';
import { Description, Field, Radio, RadioGroup } from '@headlessui/react';
import GiftIcon from '@stageplus/icons/react/gift';
import HeartIcon from '@stageplus/icons/react/heart';
import InfoIcon from '@stageplus/icons/react/info';
import SmallChevronIcon from '@stageplus/icons/react/small-chevron';
import clsx from 'clsx';
import Image from 'next/image';
import type { StaticImageData } from 'next/image';
import Link from 'next/link';
import type { Price } from 'generated/graphql';
import heroGiftCardImage from 'public/images/christmas2024/hero-gift-card.avif';
import heroSubscriptionImage from 'public/images/christmas2024/hero-subscription.avif';
import ornamentImage from 'public/images/christmas2024/ornament.svg';
import patternRingsImage from 'public/images/christmas2024/pattern-rings.svg';
import patternTopoImage from 'public/images/christmas2024/pattern-topo.svg';
import { TextButton } from 'src/components/buttons/text-button';
import CampaignCountdown from 'src/components/campaigns/campaign-countdown';
import { GiftCardsBadge } from 'src/components/gift-cards/gift-cards-badge';
import Translate from 'src/components/translate';
import { campaignEndTime, useSeasonCampaignActive } from 'src/config/campaign';
import useTranslate from 'src/hooks/use-translate';
import type { TranslationKeyCommon } from 'src/types';
import { cloudinaryLoader } from 'src/utilities/image-sdk';
import { currencyFormat } from 'src/utilities/intl-helpers';

export enum PurchaseOptionType {
  AnnualSubscription = 'AnnualSubscription',
  GiftCard = 'GiftCard',
}

type PurchaseOption = {
  type: PurchaseOptionType;
  title: TranslationKeyCommon;
  gift: TranslationKeyCommon;
  feature: TranslationKeyCommon;
  description: TranslationKeyCommon;
  featureIcon: typeof HeartIcon;
  background: StaticImageData;
};

const purchaseOptions: PurchaseOption[] = [
  {
    type: PurchaseOptionType.AnnualSubscription,
    title: 'gift_cards__options_annual_subscription_title',
    gift: 'gift_cards__options_annual_subscription_gift',
    feature: 'gift_cards__options_annual_subscription_feature_1',
    description: 'gift_cards__options_annual_subscription_description',
    featureIcon: HeartIcon,
    background: patternRingsImage as StaticImageData,
  },
  {
    type: PurchaseOptionType.GiftCard,
    title: 'gift_cards__options_gift_card_title',
    gift: 'gift_cards__options_gift_card_gift',
    feature: 'gift_cards__options_gift_card_feature_1',
    description: 'gift_cards__options_gift_card_description',
    featureIcon: GiftIcon,
    background: patternTopoImage as StaticImageData,
  },
];

function PurchaseOptionRadioButton({
  price,
  option,
  focus,
  checked,
  disabled,
  disabledText,
}: {
  price?: Price;
  option: PurchaseOption;
  focus: boolean;
  checked: boolean;
  disabled: boolean;
  disabledText?: string;
}) {
  const t = useTranslate();
  const Icon = option.featureIcon;
  return (
    <div
      className={clsx(
        'relative rounded-xl border bg-surface-600 bg-[position:center_-30%] bg-no-repeat p-3 md:gap-10 lg:p-6',
        checked ? 'border-[6px] border-campaignAccent' : 'm-[5px] border-surface-500',
        focus && 'outline outline-2 outline-offset-4 outline-focusOutline',
      )}
      style={{
        backgroundImage: disabled
          ? undefined
          : `url('${cloudinaryLoader({ src: option.background.src, width: 748 })}')`,
      }}
    >
      {disabled && (
        <Description className="absolute inset-0 flex flex-col items-center justify-center gap-2 text-balance p-4 text-center typo-caption-1">
          <InfoIcon className="rounded-full bg-textPrimary text-pageBackground" />
          <span>{disabledText}</span>
        </Description>
      )}
      <div className={clsx('flex flex-col gap-6 lg:gap-10', disabled && 'pointer-events-none opacity-[2%]')}>
        <div className="flex flex-col gap-1">
          <p className="typo-title-3">{t(option.title)}</p>
          <p className="text-campaignAccent typo-caption-1">{t(option.gift)}</p>
        </div>
        <p className="flex items-center gap-2 italic text-campaignAccent typo-caption-1 japanese:not-italic">
          <Icon className="h-4" />
          <span>{t(option.feature)}</span>
        </p>
        <div className="flex flex-col gap-1">
          <p className="text-textPrimary typo-caption-1">
            {price ? currencyFormat(price.amount, price.currency) : '…'}
          </p>
          <p className="text-textTertiary typo-caption-2">{t(option.description)}</p>
        </div>
      </div>
    </div>
  );
}

function PurchaseOptionSelector({
  price,
  selectedOption,
  onChange,
  disableAnnualSubscription,
}: {
  price?: Price;
  selectedOption: PurchaseOptionType;
  onChange: (selectedOption: PurchaseOptionType) => void;
  disableAnnualSubscription: boolean;
}) {
  const t = useTranslate();
  return (
    <RadioGroup value={selectedOption} onChange={onChange} className="flex flex-col gap-3">
      {purchaseOptions?.map((option) => {
        const isDisabled = disableAnnualSubscription && option.type === PurchaseOptionType.AnnualSubscription;
        return (
          <Field key={option.type}>
            <Radio
              value={option.type}
              className={clsx('focus-visible:outline-none', !disableAnnualSubscription && 'cursor-pointer')}
              disabled={isDisabled}
            >
              {({ checked, focus }) => (
                <PurchaseOptionRadioButton
                  price={price}
                  option={option}
                  focus={focus}
                  checked={checked}
                  disabled={isDisabled}
                  disabledText={t('gift_cards__options_annual_subscription_disabled')}
                />
              )}
            </Radio>
          </Field>
        );
      })}
    </RadioGroup>
  );
}

function PurchaseOptionIllustration({ selectedOption }: { selectedOption: PurchaseOptionType }) {
  return (
    <div
      className="relative min-h-[80vw] w-full overflow-hidden rounded-3xl border border-white/10 bg-cover bg-center bg-no-repeat md:min-h-[60vw] lg:min-h-full"
      aria-hidden
      style={{ backgroundImage: `url('${cloudinaryLoader(heroSubscriptionImage)}')` }}
    >
      <div
        className={clsx(
          'absolute inset-0 bg-cover bg-center bg-no-repeat transition-opacity',
          selectedOption === PurchaseOptionType.AnnualSubscription && 'opacity-0',
        )}
        style={{ backgroundImage: `url('${cloudinaryLoader(heroGiftCardImage)}')` }}
      />
    </div>
  );
}

type GiftCardsPurchaseHeroProps = {
  price?: Price;
  selectedOption: PurchaseOptionType;
  onChange: (selectedOption: PurchaseOptionType) => void;
  onSubmit: () => Promise<void>;
  disableAnnualSubscription: boolean;
};

export function GiftCardsPurchaseHero({
  price,
  selectedOption,
  onChange,
  onSubmit,
  disableAnnualSubscription,
}: GiftCardsPurchaseHeroProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsSubmitting(true);
    await onSubmit();
    // Reset loading state in case of an error or navigation back. Add timeout
    // to avoid non-disabled submit button appearing _before_ the navigation
    // actually happens
    setTimeout(() => setIsSubmitting(false), 1000);
  };

  const t = useTranslate();
  // check if the Christmas campaign is still on
  const isChristmasCampaignOn = useSeasonCampaignActive();

  return (
    <div className="flex flex-col gap-8">
      <h1 className="mx-auto flex max-w-[50rem] flex-col items-center gap-5 text-balance text-center">
        <GiftCardsBadge>{t('gift_cards__pretitle')}</GiftCardsBadge>
        <CampaignCountdown deadline={campaignEndTime} />
        <Image src={ornamentImage as StaticImageData} width={100} height={10} alt="" className="" unoptimized />
        <span className="block typo-large-title">{t('gift_cards__title')}</span>
      </h1>
      {/* When the campaign is over, display a special message */}
      <div
        className={clsx(
          'w-full text-balance rounded-xl border border-surface-500 bg-surface-600 py-24 text-center text-textPrimary md:mx-auto md:rounded-4xl',
          isChristmasCampaignOn && 'hidden',
        )}
        suppressHydrationWarning
        data-test="campaign-ended-message"
      >
        <div>
          <h2 className="mb-3 text-textPrimary typo-title-1">{t('gift_cards__campaign_no_longer_title')}</h2>
          <p className="text-surface-100 typo-body-1">
            <Translate
              i18nKey="gift_cards__campaign_no_longer_subtitle"
              components={{
                1: <Link href="/tickets" className="text-support-info" />,
              }}
            />
          </p>
        </div>
      </div>
      <form
        id="purchase"
        className={clsx(
          'pt-8',
          // hide the form when the campaign is over
          !isChristmasCampaignOn && 'hidden',
        )}
        onSubmit={handleSubmit}
        suppressHydrationWarning
      >
        <div className="mx-auto flex w-full flex-col gap-14 lg:flex-row">
          <PurchaseOptionIllustration selectedOption={selectedOption} />
          <div className="flex grow flex-col justify-between gap-8 lg:min-w-[28rem]">
            <div className="flex flex-col gap-1">
              <h2 className="text-textPrimary typo-title-2">{t('gift_cards__options_heading')}</h2>
              <p className="text-textTertiary typo-body-2">{t('gift_cards__options_subheading')}</p>
            </div>
            <div className="-mx-1">
              <PurchaseOptionSelector
                price={price}
                selectedOption={selectedOption}
                onChange={onChange}
                disableAnnualSubscription={disableAnnualSubscription}
              />
            </div>
            <TextButton type="submit" disabled={isSubmitting} loading={isSubmitting} data-test="signup-cta-button">
              {t('gift_cards__options_submit_button_label')}
              <span className="-mr-2">
                <SmallChevronIcon className="inline-block" aria-hidden />
              </span>
            </TextButton>
          </div>
        </div>
      </form>
    </div>
  );
}
