import { StageLogo } from '@stageplus/components/src/stage-logo';
import Link from 'next/link';
import NavigationLanguageSwitcher from 'src/components/navigation/navigation-language-switcher';
import useTranslate from 'src/hooks/use-translate';

/**
 * Header for promo campaigns: "Stage+ by Deutsche Grammophon" logo and language
 * switcher
 */
export function CampaignHeader() {
  const t = useTranslate();
  return (
    <header>
      <div className="mx-auto flex max-w-[90rem] justify-center p-5 md:px-8 lg:justify-start" lang="en">
        <div className="flex items-center gap-2 lg:gap-4">
          <Link href="/" className="rounded-sm outline-offset-2 focus-visible:focus-outline">
            <StageLogo alt={t('logo__by_dg_title')} className="h-5 w-auto lg:h-8" />
          </Link>
          <span className="italic text-surface-200 typo-caption-2" aria-hidden>
            {t('logo__by_dg_by')}
          </span>
          <img src="/images/dg-logo.png" alt="" className="h-8 lg:h-12" />
        </div>
        <nav className="ml-auto h-full content-center">
          <NavigationLanguageSwitcher />
        </nav>
      </div>
    </header>
  );
}
